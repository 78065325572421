import React, { lazy } from 'react';
import { authPages, dashboardMenu, demoPages, othersMenu } from '../menu';
import Establishment from '../pages/presentation/registers/establishment/establishment';
import Stores from '../pages/presentation/registers/stores/stores';
import Products from '../pages/presentation/pdv/product/produtos';
import Categories from '../pages/presentation/pdv/categories/categories';
import SubCategories from '../pages/presentation/pdv/subCategories/subCategories';
import Printers from '../pages/presentation/store/printers/printers';
import Complement from '../pages/presentation/pdv/complement/complement';
import Users from '../pages/presentation/registers/users/users';
import Clients from '../pages/presentation/registers/clients/clients';
import CategoryComplement from '../pages/presentation/pdv/categoryComplement/categoryComplement';
import Totens from '../pages/presentation/registers/totens/totens';
import Goals from '../pages/presentation/registers/goals/goals';
import Menus from '../pages/presentation/pdv/menus/menus';
import Stocks from '../pages/presentation/pdv/stocks/stocks';
// import Reports from '../pages/presentation/reports/Reports';
// import Notifications from '../pages/presentation/notifications/Notifications';
import Profile from '../pages/presentation/profile/Profile';
import ContactUs from '../pages/presentation/contactUs/ContactUs';
import Settings from '../pages/presentation/settings/Settings';
// import Documentations from '../pages/presentation/documentations/Documentations';
import Cashier from '../pages/presentation/store/cashier';
import StoreComplements from '../pages/presentation/store/complements';
import StoreProducts from '../pages/presentation/store/products';
import StockComponents from '../pages/presentation/store/stockComponents';
import StockProducts from '../pages/presentation/store/stockProducts';
import StoreSettings from '../pages/presentation/store/settings';
import AccessPassword from '../pages/presentation/store/accessPasswrod';
import Suggestions from '../pages/presentation/pdv/suggestions';
import BoxOffice from '../pages/presentation/command/boxOffice';
import Defect from '../pages/presentation/store/defect';
import Request from '../pages/presentation/store/request'
import Reasons from '../pages/presentation/store/reasons';
import Cupons from '../pages/presentation/store/cupons';
import FaturamentoPorDia from '../pages/presentation/reports/faturamentoPorDia';
import Lojas from '../pages/presentation/delivery/store/lojas';
import ManagementDelivery from '../pages/presentation/delivery/manager';
import Feedbacks from '../pages/presentation/reports/feedbacks';
import UserHistory from '../pages/presentation/reports/userHistory/historyUser';
import UnissuedNotes from '../pages/presentation/fiscal/unissuedNotes';
// import CategoriaComplemento from '../pages/presentation/store/complementCategory';

const LANDING = {
  DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};
const AUTH = {
  LOGIN: lazy(() => import('../pages/presentation/auth/Login')),
  PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
  TERMS_OF_USE: lazy(() => import('../pages/presentation/termsOfUse')),
};
const PAGE_LAYOUTS = {
  HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
  HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
  SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
  CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
  BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
  ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
  MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};
const EXTERNAL = {
  TERMS_OF_USE: lazy(() => import('../pages/presentation/termsOfUse')),
};
const presentation = [
  /**
   * Auth
   */
  {
    path: authPages.login.path,
    element: <AUTH.LOGIN />,
    exact: true,
  },
  {
    path: authPages.signUp.path,
    element: <AUTH.LOGIN isSignUp />,
    exact: true,
  },
  {
    path: authPages.page404.path,
    element: <AUTH.PAGE_404 />,
    exact: true,
  },

  {
    path: othersMenu.termsOfUse.path,
    element: <EXTERNAL.TERMS_OF_USE />,
    exact: true,
  },

  /** ************************************************** */
  /**
   * Landing
   */
  {
    path: dashboardMenu.dashboard.path,
    element: <LANDING.DASHBOARD />,
    exact: true,
  },
  // {
  //   path: demoPages.reports.path,
  //   element: <Reports />,
  //   exact: true,
  // },
  // {
  //   path: demoPages.documentations.path,
  //   element: <Documentations />,
  //   exact: true,
  // },
  // {
  //   path: demoPages.notifications.path,
  //   element: <Notifications />,
  //   exact: true,
  // },
  // {
  //   path: demoPages.contactUs.path,
  //   element: <ContactUs />,
  //   exact: true,
  // },

  {
    path: demoPages.settings.path,
    element: <Settings />,
    exact: true,
  },

  {
    path: authPages.profile.path,
    element: <Profile />,
    exact: true,
  },


  /** ************************************************** */

  /**
   * Page Layout Types
   */
  // {
  //   path: layoutMenu.blank.path,
  //   element: <PAGE_LAYOUTS.BLANK />,
  //   exact: true,
  // },
  // {
  //   path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
  //   element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
  //   exact: true,
  // },
  // {
  //   path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
  //   element: <PAGE_LAYOUTS.HEADER />,
  //   exact: true,
  // },
  // {
  //   path: layoutMenu.pageLayout.subMenu.onlySubheader.path,
  //   element: <PAGE_LAYOUTS.SUBHEADER />,
  //   exact: true,
  // },
  {
    path: demoPages.pageLayout.subMenu.establishment.path,
    element: <Establishment />,
    exact: true,
    min_level: demoPages.pageLayout.subMenu.establishment.min_level,
  },
  {
    path: demoPages.pageLayout.subMenu.stores.path,
    element: <Stores />,
    exact: true,
    min_level: demoPages.pageLayout.subMenu.stores.min_level,
  },
  {
    path: demoPages.pdvMenu.subMenu.categories.path,
    element: <Categories />,
    exact: true,
    min_level: demoPages.pdvMenu.subMenu.categories.min_level,
  },
  {
    path: demoPages.pdvMenu.subMenu.subCategories.path,
    element: <SubCategories />,
    exact: true,
    min_level: demoPages.pdvMenu.subMenu.subCategories.min_level,
  },
  {
    path: demoPages.store.subMenu.reasons.path,
    element: <Reasons />,
    exact: true,
    min_level: demoPages.store.subMenu.cashier.min_level,
  },
  {
    path: demoPages.store.subMenu.cupons.path,
    element: <Cupons />,
    exact: true,
    min_level: demoPages.store.subMenu.cashier.min_level,
  },
  {
    path: demoPages.pdvMenu.subMenu.complement.path,
    element: <Complement />,
    exact: true,
    min_level: demoPages.pdvMenu.subMenu.complement.min_level,
  },
  {
    path: demoPages.pdvMenu.subMenu.categoryComplement.path,
    element: <CategoryComplement />,
    exact: true,
    min_level: demoPages.pdvMenu.subMenu.categoryComplement.min_level,
  },
  {
    path: demoPages.pdvMenu.subMenu.product.path,
    element: <Products />,
    exact: true,
    min_level: demoPages.pdvMenu.subMenu.product.min_level,
  },
  {
    path: demoPages.pdvMenu.subMenu.suggestion.path,
    element: <Suggestions />,
    exact: true,
    min_level: demoPages.pdvMenu.subMenu.suggestion.min_level,
  },
  {
    path: demoPages.fiscal.subMenu.unissuedNotes.path,
    element: <UnissuedNotes />,
    exact: true,
    min_level: demoPages.fiscal.subMenu.unissuedNotes.min_level,
  },
  {
    path: demoPages.pageLayout.subMenu.totens.path,
    element: <Totens />,
    exact: true,
    min_level: demoPages.pageLayout.subMenu.totens.min_level,
  },
  {
    path: demoPages.pageLayout.subMenu.goals.path,
    element: <Goals />,
    exact: true,
    min_level: demoPages.pageLayout.subMenu.goals.min_level,
  },
  {
    path: demoPages.pageLayout.subMenu.users.path,
    element: <Users />,
    exact: true,
    min_level: demoPages.pageLayout.subMenu.users.min_level,
  },
  {
    path: demoPages.pageLayout.subMenu.clients.path,
    element: <Clients />,
    exact: true,
  },
  {
    path: demoPages.pdvMenu.subMenu.menus.path,
    element: <Menus />,
    exact: true,
    min_level: demoPages.pdvMenu.subMenu.menus.min_level,
  },
  // {
  //   path: demoPages.pdvMenu.subMenu.stocks.path,
  //   element: <Stocks />,
  //   exact: true,
  // },
  {
    path: demoPages.store.subMenu.complement.path,
    element: <StoreComplements />,
    exact: true,
  },
  // {
  //   path: demoPages.store.subMenu.complementCatergoria.path,
  //   element: <CategoriaComplemento />,
  //   exact: true,
  // },
  {
    path: demoPages.store.subMenu.product.path,
    element: <StoreProducts />,
    exact: true,
  },
  {
    path: demoPages.store.subMenu.stockProducts.path,
    element: <StockProducts />,
    exact: true,
  },
  {
    path: demoPages.store.subMenu.stockComponents.path,
    element: <StockComponents />,
    exact: true,
  },
  {
    path: demoPages.store.subMenu.cashier.path,
    element: <Cashier />,
    exact: true,
    min_level: demoPages.store.subMenu.cashier.min_level,
  },
  {
    path: demoPages.store.subMenu.defect.path,
    element: <Defect />,
    exact: true,
    min_level: demoPages.store.subMenu.defect.min_level,
  },
  {
    path: demoPages.store.subMenu.accessPassword.path,
    element: <AccessPassword />,
    exact: true,
  },
  {
    path: demoPages.store.subMenu.printers.path,
    element: <Printers />,
    exact: true,
    min_level: demoPages.store.subMenu.printers.min_level,
  },
  {
    path: demoPages.store.subMenu.settings.path,
    element: <StoreSettings />,
    exact: true,
    min_level: demoPages.store.subMenu.settings.min_level,
  },
  {
    path: demoPages.store.subMenu.request.path,
    element: <Request />,
    exact: true,
    min_level: demoPages.store.subMenu.request.min_level,
  },
  // {
  //   path: layoutMenu.pageLayout.subMenu.onlyContent.path,
  //   element: <PAGE_LAYOUTS.CONTENT />,
  //   exact: true,
  // },
  // {
  //   path: layoutMenu.asideTypes.subMenu.defaultAside.path,
  //   element: <PAGE_LAYOUTS.ASIDE />,
  //   exact: true,
  // },
  // {
  //   path: layoutMenu.asideTypes.subMenu.minimizeAside.path,
  //   element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
  //   exact: true,
  // },

  {
    path: demoPages.command.subMenu.boxOffice.path,
    element: <BoxOffice />,
    exact: true,
    min_level: demoPages.command.subMenu.boxOffice.min_level,
  },

  {
    path: demoPages.reports.subMenu.faturamentoPorDia.path,
    element: <FaturamentoPorDia />,
    exact: true,
    min_level: demoPages.reports.subMenu.faturamentoPorDia.min_level,
  },
  {
    path: demoPages.reports.subMenu.userHistory.path,
    element: <UserHistory />,
    exact: true,
    min_level: demoPages.reports.subMenu.userHistory.min_level,
  },

  {
    path: demoPages.reports.subMenu.feedbacks.path,
    element: <Feedbacks />,
    exact: true,
    min_level: demoPages.reports.subMenu.feedbacks.min_level,
  },
  {
    path: demoPages.delivery.subMenu.lojas.path,
    element: <Lojas />,
    exact: true,
    min_level: demoPages.delivery.subMenu.lojas.min_level
  },
  {
    path: demoPages.delivery.subMenu.gerenciamento.path,
    element: <ManagementDelivery/>,
    exact: true,
    min_level: demoPages.delivery.subMenu.gerenciamento.min_level
  }
];
const contents = [...presentation];

export default contents;
