import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import ModalRegisterMenu from '../../../../components/modals/Menus/ModalRegister';
import Button from '../../../../components/bootstrap/Button';
import { DefaultContext } from '../../../../contexts/default';
// Database
import MenuDB from '../../../../database/wrappers/menu';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import { orderBy } from 'firebase/firestore';
import { ACCESS_LEVEL } from '../../../../types/roles';
import UserHistory from '../../../../database/wrappers/userHistory';

const Menus = () => {
  const { accessLevel, establishment, onShowAlert, onShowQuestion, user } = useContext(DefaultContext);

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [menus, setMenus] = useState([])
  const [menuSelected, setMenuSelected] = useState({})

  useEffect(() => {
    if (!establishment) return;
    const onSubscribe = new MenuDB(establishment.id).on(setMenus, orderBy('name', 'asc'));
    return onSubscribe;
  }, [establishment])

  const handleOpenModalRegister = useCallback(() => {
    setMenuSelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setMenuSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalDelete = useCallback((row) => {
    onShowQuestion({
      title: 'Exclusão de Cardápio',
      message: 'Você está excluindo um cardápio. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new MenuDB(establishment.id)
        .delete(row.id)
        .then(res => {
          showNotification('', notificationBody('Registro deletado com sucesso'), 'success');
          new UserHistory(establishment.id, user, "o Cardapio")
              .deleteWithData(row.id, row)
              .finally(() => { })
        })
        .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [establishment])

  const handleChangeStatusLabel = useCallback((id, label, active) => {
    new MenuDB(establishment.id)
    .update(id, {
      [label]: !active
    })
    .catch(error => onShowAlert({
      title: 'ERRO!',
      msm: 'Não foi possível atualizar o status do produto',
      icon: 'Error',
      color: 'danger'
    }))
  }, [establishment])

  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'POS',
      field: 'pos',
      format: row => (
        <Button
          isLink
          color={row.pos ? 'success' : 'danger'}
          icon='Circle'
          onClick={() => handleChangeStatusLabel(row.id, 'pos', row.pos)}
          className='text-nowrap'>
          {row.pos ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Totem',
      field: 'toten',
      format: row => (
        <Button
          isLink
          color={row.toten ? 'success' : 'danger'}
          icon='Circle'
          onClick={() => handleChangeStatusLabel(row.id, 'toten', row.toten)}
          className='text-nowrap'>
          {row.toten ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <Button
          isLink
          color={row.active ? 'success' : 'danger'}
          icon='Circle'
          onClick={() => handleChangeStatusLabel(row.id, 'active', row.active)}
          className='text-nowrap'>
          {row.active ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between'>
          <Button
            color="light"
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [handleChangeStatusLabel, handleOpenModalEdit, handleOpenModalDelete])

  return (
    <>
      <PageWrapper title={demoPages.pdvMenu.subMenu.menus.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'PDV', to: '/pdv/menus' },
                {
                  title: 'Cardápios',
                  to: '/pdv/menus',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            {accessLevel >= ACCESS_LEVEL.MANAGER &&
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleOpenModalRegister}
            >
              Cadastrar
            </Button>}
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardBody className='table-responsive p-5'>
              <SelectEstablishment />

              <TableCustom
                rows={menus}
                columns={columns}
                fileName={'Cardápios'}
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterMenu
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        menuSelected={menuSelected}
      />
    </>
  );
};

export default Menus;
