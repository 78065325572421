import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Label from '../../bootstrap/forms/Label';
import Textarea from '../../bootstrap/forms/Textarea';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
// Database
import { DefaultContext } from '../../../contexts/default';
import ComplementDB from '../../../database/wrappers/complement';
import masks, { unmask } from '../../../helpers/utils/masks';
import Money from '../../../helpers/utils/money/money';
import PreAlert from '../../../helpers/utils/preAlert';
import { convertTrybuIntToPTBR, TRIBUTARY_SITUATION } from '../../../types/fiscal';
import { CardBody } from '../../bootstrap/Card';
import Select from '../../bootstrap/forms/Select';
import Avatar from '../../MyCustom/Avatar';
import Wizard, { WizardItem } from '../../Wizard';
import UserHistory from '../../../database/wrappers/userHistory';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  }

  const price = unmask(values.price);
  if (isNaN(Number(price)) || Number(price) < 0) {
    errors.price = "Informe um preço válido";
  }

  if (!values.active) {
    errors.active = 'Você precisa escolher o status do complemento';
  }


  // Informaçoes Fiscais
  if (!values.fiscal_data_bar_code) {
    errors.fiscal_data_bar_code = 'Este campo é necessário';
  }
  if (!values.fiscal_data_ncm) {
    errors.fiscal_data_ncm = 'Este campo é necessário';
  }
  if (!values.fiscal_data_cst) {
    errors.fiscal_data_cst = 'Este campo é necessário';
  }
  // if (!values.fiscal_data_csosn) {
  //   errors.fiscal_data_csosn = 'Este campo é necessário';
  // }
  // if (!values.fiscal_data_cest) {
  //   errors.fiscal_data_cest = 'Este campo é necessário';
  // }
  if (!values.fiscal_data_cfop) {
    errors.fiscal_data_cfop = 'Este campo é necessário';
  }
  // if (!values.fiscal_data_aliq_icms) {
  //   errors.fiscal_data_aliq_icms = 'Este campo é necessário';
  // }
  if (!Object.keys(TRIBUTARY_SITUATION).find(key => TRIBUTARY_SITUATION[key] == values.fiscal_data_tributary_situation)) {
    errors.fiscal_data_tributary_situation = 'Selecione uma opção necessário';
  }

  return errors;

}

const ModalRegisterComplement = ({ open, setIsOpen, setIsClose, complementSelected }) => {

  const { establishment, settings, onShowAlert, user } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (complementSelected) {
      const { name, secondName, sku, price, description, active, fiscal_data, order, image_url, second_image_url, orderDelivery, delivery_code } = complementSelected;

      formik.setValues({
        name: name,
        secondName: secondName === undefined ? '' : secondName,
        sku: sku === undefined ? '' : sku,
        price: Money.centsToCoin(price).toFixed(2),
        description: description,
        active: active ? 'ativo' : 'inativo',
        image: null,
        second_image: null,
        second_image_url: second_image_url === undefined ? null : second_image_url,
        image_url,
        order: order === undefined ? null : order,
        orderDelivery : orderDelivery ? orderDelivery : null,
        delivery_code: delivery_code || null,
        // fiscal data
        fiscal_data_bar_code: fiscal_data?.bar_code,
        fiscal_data_ncm: fiscal_data?.ncm,
        fiscal_data_cst: fiscal_data?.cst,
        fiscal_data_csosn: fiscal_data?.csosn,
        fiscal_data_cest: fiscal_data?.cest,
        fiscal_data_cfop: fiscal_data?.cfop,
        fiscal_data_aliq_icms: fiscal_data?.aliq_icms ? fiscal_data?.aliq_icms : '',
        fiscal_data_tributary_situation: fiscal_data?.tributary_situation,
      });
    }
  }, [complementSelected, open])

  const formik = useFormik({
    initialValues: {
      name: '',
      secondName: '',
      sku: '',
      value: '',
      price: '',
      description: '',
      active: '',
      order: '',
      orderDelivery: '',
      delivery_code: '',

      // fiscal data
      fiscal_data_bar_code: '',
      fiscal_data_ncm: '',
      fiscal_data_cst: '',
      fiscal_data_csosn: '',
      fiscal_data_cest: '',
      fiscal_data_cfop: '',
      fiscal_data_aliq_icms: '',
      fiscal_data_tributary_situation: '',

      image: null,
      image_url: null,

      second_image: null,
      second_image_url: null
    },
    validate,
    onSubmit: values => {
      const {
        name,
        secondName,
        sku,
        description,
        price,
        active,
        image,
        second_image,
        order,
        orderDelivery,
        delivery_code,
        fiscal_data_bar_code,
        fiscal_data_ncm,
        fiscal_data_cst,
        fiscal_data_csosn,
        fiscal_data_cest,
        fiscal_data_cfop,
        fiscal_data_aliq_icms,
        fiscal_data_tributary_situation,
      } = values;

      const fiscal_data = {
        bar_code: fiscal_data_bar_code,
        ncm: fiscal_data_ncm,
        cst: fiscal_data_cst,
        csosn: fiscal_data_csosn,
        cest: fiscal_data_cest,
        cfop: fiscal_data_cfop,
        aliq_icms: fiscal_data_aliq_icms === undefined ? null : fiscal_data_aliq_icms,
        tributary_situation: fiscal_data_tributary_situation
      };

      const data = {
        name,
        secondName: secondName === undefined ? null : secondName,
        sku: sku === undefined ? null : sku,
        price: Number(unmask(price)),
        description: description === undefined ? null : description,
        active: active === 'ativo',
        order: order ? Number(order) : null,
        orderDelivery: orderDelivery ? Number(orderDelivery) : null,
        delivery_code: delivery_code || null,
        fiscal_data,
        image,
        second_image,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (complementSelected) {
        new ComplementDB(establishment.id)
          .update(complementSelected.id, data)
          .then(() => {
            onSuccessUpdate();
            new UserHistory(establishment.id, user, "o Complemento")
              .update(complementSelected.id, { data, selected: complementSelected })
              .finally(() => { })
          })
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new ComplementDB(establishment.id)
          .create(data)
          .then(() => {
            onSuccess();
            new UserHistory(establishment.id, user, "o Complemento")
              .create(data)
              .finally(() => { })
          })
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  const checkStepIsValid = (step) => {
    if (step === 'step1') {
      const price = unmask(formik.values.price);
      if (isNaN(Number(price)) || Number(price) < 0)
        return true;
      else if (
        formik.values.name !== '' &&
        formik.values.active !== '' &&
        formik.values.price !== ''
      ) {
        return false;
      } else {
        return true;
      }
    }
    // if (step === 'step2') {
    //   if (
    //     formik.values.product_qtd !== '' &&
    //     formik.values.product_unit !== '' &&
    //     formik.values.product_unit_value !== '' &&
    //     formik.values.product !== ''
    //   ) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // }
  }

  const handleImage = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      image: file,
      image_url: URL.createObjectURL(file)
    })
  }, [formik.values])

  const handleImageSecond = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      second_image: file,
      second_image_url: URL.createObjectURL(file)
    })
  }, [formik.values])

  const handleChangeOrder = useCallback(e => {
    const order = e.target.value.replace(/\D/g, "");
    formik.setValues({
      ...formik.values,
      order
    })
  }, [formik.values])

  const optionsSituation = useMemo(() => Object.keys(TRIBUTARY_SITUATION).map(key => ({ value: TRIBUTARY_SITUATION[key], text: convertTrybuIntToPTBR(TRIBUTARY_SITUATION[key]) })), [])

  return (
    <Modal
      id={'modal-register-complement'}
      titleId={'Cadastro de Complemento'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader className='p-4 mt-2 justify-content-start' setIsOpen={setIsOpen}>
        <ModalTitle id="register-complement">{complementSelected ? 'Atualização de Complemento' : 'Cadastro de Complemento'}</ModalTitle>
      </ModalHeader>
      <ModalBody className='p-0'>
        <Wizard
          isHeader
          noValidate
          color='info'
          onSubmit={formik.handleSubmit}
          className='p-4 m-0'
          isLoading={isLoading}
        >
          <WizardItem id='step1' title='Informações do Complemento' required={checkStepIsValid('step1')}>
            {/* Imagem do Produto */}
            <CardBody className='p-0 '>
              <div className='row gap-4'>
                <div className='d-flex align-items-center justify-content-center gap-4'>
                  <FormGroup
                    id='image_url'
                    ariaLabel='Logo'
                    label="Primária"
                  >
                    <Avatar
                      id='image_url'
                      value={formik.values.image_url}
                      onChange={handleImage} />
                  </FormGroup>

                  {settings?.ecommerce &&
                    <FormGroup
                      id='second_image_url'
                      ariaLabel='Logo'
                      label="Secundária"

                    >
                      <Avatar
                        id='second_image_url'
                        value={formik.values.second_image_url}
                        onChange={handleImageSecond} />
                    </FormGroup>
                  }

                </div>
                {/* Inputs */}
                <div className="row  pb-2 d-flex">
                  {/* Nome */}
                  <FormGroup id="name" label="Nome" className='col-md-8 mb-4'>
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      isValid={formik.isValid}
                      isTouched={formik.touched.name}
                      invalidFeedback={formik.errors.name}
                      validFeedback='Assim está bom!'
                      placeholder='Eletrodomésticos, lâmpadas, etc...'
                    />
                  </FormGroup>

                  {/* Value */}
                  <FormGroup id="price" label="Preço" className='col-md-4 mb-4'>
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={masks.money(formik.values.price)}
                      isValid={formik.isValid}
                      isTouched={formik.touched.price}
                      invalidFeedback={formik.errors.price}
                      validFeedback='Assim está bom!'
                      placeholder='10,00'
                    />
                  </FormGroup>

                  {/* Ordem */}
                  <FormGroup id="order" label="Ordem de Exibição" className='col-md-4 mb-4'>
                    <Input
                      onChange={handleChangeOrder}
                      onBlur={formik.handleBlur}
                      value={formik.values.order || ''}
                      isValid={formik.isValid}
                      isTouched={formik.touched.order}
                      invalidFeedback={formik.errors.order}
                      validFeedback='Assim está bom!'
                      placeholder='1, 2, 3, 4 etc...'
                    />
                  </FormGroup>

                  {settings?.ecommerce &&

                    <FormGroup id="secondName" label="Nome Informativo" className='col-md-4 mb-4'>
                      <Input
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.secondName}
                        isValid={formik.isValid}
                        isTouched={formik.touched.secondName}
                        invalidFeedback={formik.errors.secondName}
                        validFeedback='Assim está bom!'
                        placeholder='Eletrodomésticos, lâmpadas, etc...'
                      />
                    </FormGroup>
                  }

                  <FormGroup id="sku" label="SKU" className='col-md-4 mb-4'>
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.sku}
                      isValid={formik.isValid}
                      isTouched={formik.touched.sku}
                      invalidFeedback={formik.errors.sku}
                      validFeedback='Assim está bom!'
                      placeholder='SKU'
                    />
                  </FormGroup>

                  {/* Ordem Delivery */}
                  <FormGroup id="orderDelivery" label="Ordem no Delivey" className='col-md-4 mb-4'>
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.orderDelivery || ''}
                      isValid={formik.isValid}
                      isTouched={formik.touched.orderDelivery}
                      invalidFeedback={formik.errors.orderDelivery}
                      validFeedback='Assim está bom!'
                      placeholder='Ex: 1'
                    />
                  </FormGroup>

                  {/* Cód Delivery */}
                  <FormGroup id="delivery_code" label="Cód Delivery" className='col-md-3 mb-4'>
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.delivery_code || ''}
                      isValid={formik.isValid}
                      isTouched={formik.touched.delivery_code}
                      invalidFeedback={formik.errors.delivery_code}
                      validFeedback='Assim está bom!'
                      placeholder='Ex: 1'
                    />
                  </FormGroup>

                  {/* Descrição */}
                  <FormGroup id="description" label="Descrição" className='col-md-12 mb-4'>
                    <Textarea
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      isValid={formik.isValid}
                      isTouched={formik.touched.description}
                      invalidFeedback={formik.errors.description}
                      validFeedback='Assim está bom!'
                      placeholder='Informe, se desejar, uma descrição para o complemento'
                    />
                  </FormGroup>

                  {/* Status */}
                  <FormGroup className='col-md-4 mb-4'>
                    <Label>Status</Label>
                    <ChecksGroup
                      isValid={formik.isValid}
                      isTouched={formik.touched.active}
                      invalidFeedback={formik.errors.active}
                    >
                      <Checks
                        type="radio"
                        id="active"
                        label="Ativo"
                        name="active"
                        value="ativo"
                        onChange={formik.handleChange}
                        checked={formik.values.active}
                        isInline
                      />
                      <Checks
                        type="radio"
                        id="active2"
                        label="Inativo"
                        name="active"
                        value="inativo"
                        onChange={formik.handleChange}
                        checked={formik.values.active}
                        isInline
                      />
                    </ChecksGroup>
                  </FormGroup>
                </div>
              </div>
            </CardBody>
          </WizardItem>

          <WizardItem id='step4' title='Informações Fiscais'>
            <div className="row pb-2 d-flex">
              {/* Bar Code */}
              <FormGroup id='fiscal_data_bar_code' label='Código de Barras' className='col-md-6 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_bar_code}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_bar_code}
                  invalidFeedback={formik.errors.fiscal_data_bar_code}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'

                />
              </FormGroup>

              {/* NCM */}
              <FormGroup id='fiscal_data_ncm' label='NCM' className='col-md-6 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_ncm}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_ncm}
                  invalidFeedback={formik.errors.fiscal_data_ncm}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'

                />
              </FormGroup>

              {/* CST */}
              <FormGroup id='fiscal_data_cst' label='CST / ICMS' className='col-md-4 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_cst}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_cst}
                  invalidFeedback={formik.errors.fiscal_data_cst}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'

                />
              </FormGroup>

              {/* CSOSN */}
              <FormGroup id='fiscal_data_csosn' label='CSOSN' className='col-md-4 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_csosn}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_csosn}
                  invalidFeedback={formik.errors.fiscal_data_csosn}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'
                />
              </FormGroup>

              {/* CEST */}
              <FormGroup id='fiscal_data_cest' label='CEST' className='col-md-4 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_cest}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_cest}
                  invalidFeedback={formik.errors.fiscal_data_cest}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'
                />
              </FormGroup>

              {/* CFOF */}
              <FormGroup id='fiscal_data_cfop' label='CFOP' className='col-md-6 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fiscal_data_cfop}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_cfop}
                  invalidFeedback={formik.errors.fiscal_data_cfop}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 798632654214'
                />
              </FormGroup>



              {/* Situação Tributária */}
              <FormGroup id='fiscal_data_tributary_situation' label='Situação Tributária' className='col-md-6 mb-4'>
                <Select
                  placeholder='Selecione'
                  value={formik.values.fiscal_data_tributary_situation}
                  list={optionsSituation}
                  onChange={formik.handleChange}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fiscal_data_tributary_situation}
                  invalidFeedback={formik.errors.fiscal_data_tributary_situation}
                />
              </FormGroup>

              {(formik.values.fiscal_data_tributary_situation === "1") && (
                <>
                  {/* Aliquota ICMS */}
                  <FormGroup id='fiscal_data_aliq_icms' label='Alíquota ICMS' className='col-md-4 mb-4'>
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.fiscal_data_aliq_icms}
                      isValid={formik.isValid}
                      isTouched={formik.touched.fiscal_data_aliq_icms}
                      invalidFeedback={formik.errors.fiscal_data_aliq_icms}
                      validFeedback='Assim está bom!'
                      placeholder='Ex: 18.00'
                    />
                  </FormGroup>
                </>
              )}
            </div>
          </WizardItem>
        </Wizard>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterComplement;