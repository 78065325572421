import React, { useState, useContext, useEffect, memo } from 'react';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';

import UserHistory from '../../../database/wrappers/userHistory';
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import masks, { unmask } from '../../../helpers/utils/masks';
import StoreComplementDB from '../../../database/wrappers/storeComplement';

const validate = (values) => {
  const errors = {};

  const value = Number(unmask(values.price))
  if (!values.price || isNaN(value)) {
    errors.price = 'Informe um número válido';
  }

  return errors;
}

const ModalRegisterStoreComplements = ({ open, setIsOpen, setIsClose, complementtSelected }) => {

  const { establishment, store, onShowAlert, user } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(!open) return formik.resetForm();
    if (complementtSelected) {
      const { active, price } = complementtSelected;

      formik.setValues({
        price: unmask(String(price)),
        active: active ? 'ativo' : 'inativo',
      });
    }
  }, [complementtSelected, open])

  const formik = useFormik({
    initialValues: {
      active: '',
      price: '',
    },
    validate,
    onSubmit: values => {
      const { active, price } = values;

      const data = {
        active: active === 'ativo',
        price: Number(unmask(price)),
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true)
      new StoreComplementDB(establishment.id, store.id).get(complementtSelected.id)
        .then((complement) => {
          if (complement) {
            new StoreComplementDB(establishment.id, store.id)
              .update(complementtSelected.id, data)
              .then(()=> {
                onSuccessUpdate();
                new UserHistory(establishment.id, user, "o Complemento", store.name)
                .update(complementtSelected.id, { data, selected: complementtSelected, active: data.active })
                .finally(() => { })
              })
              .catch(onError)
              .finally(() => {})
          }
        })
        .catch(() => {
          new StoreComplementDB(establishment.id, store.id)
          .define(complementtSelected.id, data)
          .then(() => {
            onSuccess();
            new UserHistory(establishment.id, user, "o Complemento", store.name)
            .update(complementtSelected.id, { data, selected: complementtSelected, active: data.active })
            .finally(() => { })
          })
          .catch(onError)
          .finally(() => {})
        })
        .finally(() => setIsLoading(false))
    },
  })

  return (
    <Modal
      id={'modal-register-totem'}
      titleId={'Cadastro de Totem'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-totem">{complementtSelected ? 'Atualização de Complemento' : 'Cadastro de Complementos'}</ModalTitle>
      </ModalHeader>
      <ModalBody className='p-5'>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className='d-flex flex-column align-items-center col-md-12'>
            <img src={complementtSelected?.image_url} height={100} width={100} />
            <h3 style={{ marginTop: 20, marginBottom: 20 }}>{complementtSelected?.name}</h3>
          </div>
          {/* Inputs */}
          <div className="row pb-2 d-flex">
            {/* key */}
            <FormGroup
              id='price' label='Preço' className='col-md-4 mb-4'
            >
              <Input
                // type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={masks.money(formik.values.price)}
                isValid={formik.isValid}
                isTouched={formik.touched.price}
                invalidFeedback={formik.errors.price}
                validFeedback='Assim está bom!'
                placeholder='R$ 0,00'
              />
            </FormGroup>

            {/* Status */}
            <FormGroup className='col-md-4 mb-4'>
              <Label>Status</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="radio"
                  id="active"
                  label="Ativo"
                  name="active"
                  value="ativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
                <Checks
                  type="radio"
                  id="active2"
                  label="Inativo"
                  name="active"
                  value="inativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
              </ChecksGroup>
            </FormGroup>
          </div>



          {/* Buttons */}
          <div className="row pt-4">         
              <div className='d-flex justify-content-end gap-2'>
                <Button
                  color="danger"
                  icon="cancel"
                  rounded={1}
                  onClick={setIsClose}
                  shadow="sm"
                  hoverShadow="sm"
                  size="sm"
                >
                  Cancelar
                </Button>
                {isLoading ? (
                  <Button
                    color="success"
                    rounded={1}
                    hoverShadow="sm"
                    shadow="sm"
                    size="sm"
                  >
                    <Spinner
                      color="light"
                      inButton
                      isGrow
                      isSmall
                      size={10}
                      tag="span"
                    >
                      Carregando...
                    </Spinner>
                    Carregando...
                  </Button>
                ) : (
                  <Button
                    isDisable={!formik.isValid && !!formik.submitCount}
                    type='submit'
                    color='success'
                    icon="check"
                    rounded={1}
                    hoverShadow="sm"
                    shadow="sm"
                    size="sm"
                  >
                    Confirmar
                  </Button>
                )}
              </div>
         
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterStoreComplements);